.diff-viewer {
  font-family: "Courier New", Courier, monospace;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

.diff-item {
  margin-bottom: 20px;
}

.diff-path {
  font-weight: bold;
  margin-bottom: 5px;
}

.diff-values {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.diff-label {
  font-weight: bold;
  display: block;
  margin-bottom: 3px;
}

.diff-listing,
.diff-backend {
  width: 48%;
  padding: 10px;
  background-color: #fff;
}

.diff-added {
  background-color: #e6ffed;
  color: #22863a;
  border-left: 4px solid #34d058;
  padding-left: 10px;
}

.diff-removed {
  background-color: #ffeef0;
  color: #cb2431;
  border-left: 4px solid #d73a49;
  padding-left: 10px;
}

pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
}
