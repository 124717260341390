.app {
  display: flex;
  .left {
    overflow: auto;
    padding: 20px 20px 0 20px;
    width: 160px;
    height: 100vh;
    position: fixed;
    background-color: #000022;
    a {
      color: rgb(226, 226, 226);
      text-decoration: none;
    }

    a:hover {
      color: white;
      cursor: pointer;
    }

    .my-logo {
      height: 75px;
      border-radius: 4px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
    }
  }
  .right {
    margin-left: 200px;
    overflow: auto;
    flex: 1;
    height: 100vh;
    background-color: #e9e9e9;
    a {
      color: #2176ae;
      text-decoration: underline;
    }
  }
}

.myspace {
  > * {
    margin-bottom: 20px;
  }
}
