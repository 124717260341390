body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  color: #3f2f2f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

iframe {
  display: none;
}

.d-flex {
  display: flex;
}

.d-flex-center {
  display: flex;
  align-items: center;
}

.d-flex-end {
  display: flex;
  justify-content: end;
}

.dg-item {
  margin-bottom: 12px;
}

.mr-12 {
  margin-right: 12px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-24 {
  margin-left: 24px;
}

.align-center {
  align-items: center;
}

.ck-editor__editable {
  min-height: 190px;
  // max-height: 250px;
}

table {
  background-color: white;
}

.warning {
  color: crimson;
}

.aws-btn {
  background: forestgreen;
  color: white;
}

.aws-btn:hover {
  background: green !important;
  color: white;
}

.violet {
  color: blueviolet;
}
