/* src/components/Login.css */
.login-pre-container {
  background-color: #303235;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  // margin-top: -150px;
  .login-container {
    width: 400px;
    margin: 0 auto;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #eee;
  }

  .login-container h2 {
    text-align: center;
  }

  .login-container form {
    display: flex;
    flex-direction: column;
  }

  .login-container label {
    margin-bottom: 0.5rem;
  }

  .login-container input {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .login-container button {
    padding: 0.5rem;
    border: none;
    background-color: #000022;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }

  .login-container button:hover {
    background-color: #04043d;
  }
}
